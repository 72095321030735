import {UbicacionModel} from "./ubicacion";
import { respuestasModel} from "./respuesta-pregunta";

export class RespuestaCuestionarioModel {
  //listo
  public idCuestionario: number;
  //listo
  public idUser: number;
  //listo
  public idVehiculo: number;
  //listo
  public fechaHora: string;
  //
  public fechaRespuesta: string;
  //listo
  public ubicacion: UbicacionModel;
  public latitud:number;
  public longitud:number;
  public kilometraje:number;
  public combustible:number;
  public inicioViaje: string;
  public finViaje: string;
  public conPasajero: number;
  public nombrePasajero: string;
  public observacion1: string;
  public observacion2: string;
  public observaciones: string;
  public respuestas: Array<respuestasModel>;
  public rut:string;
  public asignacionGoldfieldsId:number;
  public firma:string = null;
  public nombreObservado:string = null;
  public rutObservado:string = null;
  public cargoObservado:string = null;
  public areaObservado:string = null;
  public clienteObservado:string = null;
  public siteObservado:string = null;
  public version: string = "w-iss-1.0.0";
  public idViaje: number = null;
}
