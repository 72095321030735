//import {PreguntaModel} from "./pregunta";
//import { List } from "ionic-angular";

import { ResultadoPuntaje } from "./resultado-puntaje";

export class SeccionModel{    
    public id:number;
    public titulo:string;
    public sumatoria:boolean;
    public puntajeTotal:number = 0;
    public resultadosPuntaje:Array<ResultadoPuntaje>
    //public preguntas:Array<PreguntaModel>;
  }