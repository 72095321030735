import {OpcionModel} from "./opcion";

export class PreguntaModel{

  public id:number;
  public numeroPregunta:number;
  public enunciado:string;
  public descripcion:string;
  public opciones:Array<OpcionModel>;
  public idSeccion:number;
  public idTipoPregunta:number;
  public preseleccionada:boolean;
  public imagen:string;
  public critico:boolean;
}
